html {
    scroll-behavior: smooth;
}

html, body {
    height: 100% !important;
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

:root {
    --dark: #26262b;
    --dark-2: #7D85A7;

    --primary-color: #214CE2;
    --primary-color-darker: #0d89ec;
    --background-color: #EAEFFA;
    --dark-green: #07767d;
    --success: #00CC25;
    --warning: #F59E0B;
    --danger: #FF1500;
    --border-radius: 8px;
    --text: #36416E;
    --text-light: #7D85A7;
    font-size: 14px;
}
